<template>
  <v-container
    :class="{
      'title-item-dark': $vuetify.theme.dark,
    }"
    fluid
  >
    
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <ButtonAdicionar :temPermissao="true" to="/feriados/adicionar" />
        </v-col>
      </v-row>
    </v-card-actions>

    <v-row class="mt-4">
      <v-col>
        <v-data-table
          disable-filtering
          disable-sort
          :server-items-length="totalRegistros"
          :headers="cabecalho"
          :items="items"
          :options.sync="paginacao"
          :footer-props="{
            'items-per-page-options': [10, 20, 50],
          }"
          @update:options="carregarFeriados"
        >
          <template v-slot:[`item.actions`]="{ item }" class="text-right">
            <table-btn-mostrar
              :temPermissao="
                $store.getters['usuario/temPermissao']('mostrar.feriados')
              "
              :href="`/feriados/${item.id}/mostrar`"
            />
            <table-btn-editar
              :temPermissao="
                $store.getters['usuario/temPermissao']('editar.feriados')
              "
              :href="`/feriados/${item.id}/editar`"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import feriados from "@/api/feriados";

export default {
  components: {
    ButtonAdicionar: () => import("@/components/button/ButtonAdicionar"),
  },

  data() {
    return {
      tab: 2,
     
      totalRegistros: 0,
      idExcluir: null,
      cabecalho: [
        { text: "#", value: "id" },
        { text: "Descrição", value: "descricao" },
        { text: "Status", value: "status" },
        { text: "Tipo", value: "fixo" },
        { text: "Ações", value: "actions", align: "right" },
      ],
      items: [],
      paginacao: {},
    };
  },

  methods: {
    async carregarFeriados() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);

        const resposta = await feriados.listar({
          pagina: this.paginacao.page,
          por_pagina: this.paginacao.itemsPerPage,
        });

        this.items = resposta.data.data;
        this.items.forEach((item) => {
          if (item.ativo == 1) {
            item.status = "Ativo";
          } else {
            item.status = "Inativo";
          }
        });
        this.items.forEach((item) => {
          if (item.fixo == 1) {
            item.fixo = "Feriado Fixo";
          } else {
            item.fixo = "";
          }
        });

        this.totalRegistros = resposta.data.meta.total;
      } catch (e) {
        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },
  },
};
</script>
