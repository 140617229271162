import axios from '@/plugins/axios'

export default {
    async listar (dados) {
        const parametros = new URLSearchParams(dados).toString()
        return await axios.get(`feriados?${parametros}`)
    },
    async buscar (id) { 
        return await axios.get(`feriados/${id}`)
    },

    async inserir (dados) {
        return await axios.post('feriados', dados)
    },

    async atualizar (id, dados) {
        return await axios.put(`feriados/${id}`, dados)
    },
}
